'use client'
import React, { useState } from 'react';
import { FaEnvelope, FaFacebook, FaInstagram, FaMapMarker, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const Footer = () => {

  const [emailValue,setEmailValue]= useState('');
  // Useful links
  const menu = [
    {
        name: "About Us",
        page: "https://www.alafnanhr.com/about",
    },
    {
        name: "Executive Talent Quest",
        page: "https://www.alafnanhr.com/executive-talent",
    },
    {
        name: "Global Talent Bridge",
        page: "https://www.alafnanhr.com/global-talent-bridge",
    },
    {
        name: "Strategic HR Advisory",
        page: "https://www.alafnanhr.com/strategic-hr-advisory",
    },
    {
        name: "Professional Resonance",
        page: "https://www.alafnanhr.com/professional-resonance",
    },
    {
        name: "Jobs",
        page: "https://jobs.alafnanhr.com/",
    },
    {
        name: "Area of Expertise",
        page: "https://www.alafnanhr.com/area-of-expectise",
    },
    {
        name: "Connect With Us",
        page: "https://www.alafnanhr.com/contact",
    },

];


 
// const subScribe = async () => {
//   try {
//     console.log("this is the emailValue:: ", emailValue);

//     // Send a POST request to the API with the emailValue
//     const serverResponse = await fetch(`https://zia1-git-main-ikerpaster.vercel.app/api/subscriber?email=${emailValue}`, {
//       method: 'POST',
//     });

//     if (serverResponse.status === 200) {
//       console.log('Email sent successfully');
//     } else {
//       console.error('Failed to send email');
//     }
//   } catch (error) {
//     console.error('Error sending email:', error);
//   }
// };


  return (
    <div className="BG2 h-fit py-10">
      <div className="container mx-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        {/* Footer header */}
        <div className="p-4">
          <img src="/images/logo.png" width={300} height={100} alt="Logo" />
          <div className="text-sm my-3 text-gray-200">
            It's a heartfelt truth that our unwavering dedication to excellence in HR consultancy has a profound impact, much like how the beauty of meaningful content can capture the heart when gracefully presented.
          </div>
          <div className="flex gap-3 items-center text-white">
            {/* Social media */}
            <div>Follow Us</div>
            <div className="flex items-center gap-2">
              <a href="#">
                <FaFacebook />
              </a>
              <a href="#">
                <FaTwitter />
              </a>
              <a href="#">
                <FaInstagram />
              </a>
              <a href="#">
                <FaYoutube />
              </a>
            </div>
          </div>
        </div>

        {/* Address Information */}
        <div className="p-4">
          <div className="text-2xl text-gray-100">Address Information</div>
          <ContactItem
            icon={<FaWhatsapp className="h-6" />}
            label="Phone Whatsapp"
            value="+971 502310110"
          />
          <ContactItem
            icon={<FaPhone className="h-6" />}
            label="Phone Call"
            value="+9712 563 3881"
          />
          <ContactItem
            icon={<FaEnvelope className="h-6" />}
            label="Email Address"
            value="info@alafnanhr.com"
          />
          <ContactItem
            icon={<FaMapMarker className="h-6" />}
            label="Address"
            value="9G45+GC8 - Musaffah - M-33 - Abu Dhabi, UAE"
          />
        </div>

        {/* Useful Links */}
        <div className="p-4">
          <div className="text-2xl text-gray-100">Useful Links</div>
          <div className="mt-4 text-gray-100 grid grid-cols-2 gap-4 md:gap-5 text-sm ">
            {menu.map((e) => (
              <a key={e.name} href={e.page}>
                {e.name}
              </a>
            ))}
          </div>
        </div>

        {/* Subscribe to Newsletter */}
        {/* <div className="p-4">
          <div className="text-2xl text-gray-100">Subscribe To Our Newsletter</div>
          <div className="text-sm my-4 text-gray-200">
            Subscribe to Our Newsletter for the latest HR insights and innovations, aligning workforce management with your goals.
          </div>
          <div>
      <input
        type="email"
        placeholder="Your Email"
        className="py-3 rounded-lg w-full my-2 px-3"
        value={emailValue} // Assign the emailValue state to the input's value prop
        onChange={(e) => setEmailValue(e.target.value)} // Update the emailValue state when the input changes
      />
      <div onClick={subScribe} className="text-center bg-green-400 text-white my-5 p-3 rounded-md shadow-xl cursor-pointer">
        Subscribe Now
      </div>
    </div>
        </div> */}
      </div>

      {/* Last Footer */}
      <div className="py-3 text-center  text-white px-5">
        &copy; <span className="text-green-700 text-xs">Al Afnan</span> Developed By{' '}
        <span className="text-green-700">Iker</span>
      </div>
    </div>
  );
};

function ContactItem({ icon, label, value }) {
  return (
    <div className="flex items-center gap-2 mt-2">
      <div className="h-10 w-10 bg-gray-200 flex justify-center items-center rounded-lg hover:bg-green-500 hover:text-white animations text-green-500">
        {icon}
      </div>
      <div className="flex flex-col">
        <span className="text-sm text-green-400">{label}</span>
        <span className="text-sm font text-white">{value}</span>
      </div>
    </div>
  );
}

export default Footer;
