 
import Apply from '../components/ApplyForm';
import JobDetailsSkeleton from '../components/skeletons/jobDetailsSkeleton';
import moment from 'moment';
 
import React from 'react';
import { useLocation } from 'react-router-dom';
 
import useSWR from 'swr';

const fetcher = async (url) => {
  const res = await fetch(url);
  const data = await res.json();
  if (!res.ok) {
    const error = new Error(data.message);
    throw error;
  }

  return data;
};

 
  export default function JOBDetails() {

  // const searchParams = useSearchParams();
  // const app = searchParams.get('j');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Now you can use searchParams.get
  const app = searchParams.get('j');


// const app = "123";

  const { data, error } = useSWR(`https://zia1-git-main-ikerpaster.vercel.app/api/Jobs/${app}`, fetcher);

  if(error) return <> same error </>


 console.log("nonjya:: ",data);

 

  return (
<div className='w-full bg-white my-3 rounded-2xl shadow-2xl flex flex-col md:flex-row'>
  {/* Job Details */}
  {!data ? (
    <div className='w-full md:w-75 flex flex-col h-screen p-5 bg-red-500x'>
      <JobDetailsSkeleton />
    </div>
  ) : (
    <div className='w-full md:w-75 flex flex-col h-screen p-5 bg-red-500x'>
      <div className='bg-gray-50 my-2 p-10 shadow-lg w-full'>
        <div className='flex flex-col md:flex-row justify-between'>
          <div className='font-bold text-xl'>{data.title}</div>
          <div className='text-gray-600 mt-4 md:mt-0'>
            {data?.application.length} Applied for this Job
          </div>
        </div>
        <div className='text-lg font-semibold mt-4'>
          Job type: {data?.jobType}
        </div>
        <div className='text-gray-600 mt-4'>
          {moment(data.createdAt).fromNow()}
        </div>
      </div>

      <div className='my-10'>{data.description}</div>

      {/* Job Footer */}
      <div className='font-bold'>
        <div className='text-xl'>Industry: {data?.category}</div>
        <div className='text-xl'>Skills: {data?.skills}</div>
        <div className='text-xl'>
          {data?.experience ? `Experience: ${data.experience}` : 'No experience available'}
        </div>
      </div>
    </div>
  )}
      {/* Apply for a Job */}
      {/* Apply for a Job */}
  <div className='w-full md:w-25 h-screen bg-green-50 p-4 text-gray-700'>
    <div className='py-5'>Apply For this Job</div>
    <Apply />
  </div>
</div>
 
  );
}

 
