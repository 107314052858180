import Navbar from "./Navbar"
import Home from "./pages/Home"
import { Route, Routes } from "react-router-dom"
import { TopNav } from "./components/shared/TopNav"
import MiddleNav from "./components/shared/MiddleNav"
import Footer from "./components/shared/Footer"
import JobListing from "./pages/JobsListing"
import JOBDetails from "./pages/JobsDetails"
import { ToastContainer } from "react-toastify"
import About from "./pages/About"

function App() {
  return (
    <div className='w-full h-fit'> 
     <ToastContainer  position="top-center" />
    <TopNav />
    <MiddleNav />
      <Navbar />
      <div className='container bg-red-400x my-2 flex justify-between items-center mx-auto pr-4'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="./jobs-listing" element={<JobListing />} />
          <Route path="./job-details" element={<JOBDetails />} />
          <Route path="https://jobs.alafnanhr.com/about" element={<About />} />
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
