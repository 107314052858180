import React from 'react'
import { FaEnvelope, FaMapMarker, FaPhone, FaWhatsapp } from 'react-icons/fa'

const MiddleNav = () => {
  return (
    <div className='hidden container bg-red-400x my-10 md:flex justify-between items-center mx-auto pr-4'>
    <a href='https://www.alafnanhr.com' className=''>
        <img src="/images/logo.png" width={300} height={100} />
    </a>

    {/* menus and contacts  */}
    <div className='hidden md:flex gap-6'>
        <div className='flex items-center gap-2'>
            <div className='h-10 w-10 bg-gray-200 flex justify-center items-center rounded-lg hover:bg-green-500 hover:text-white animations text-green-500 '><FaWhatsapp className='h-6 ' /></div>
            <div className='flex flex-col'>
                <span className='text-sm'>Phone Whatsapp</span>
                <span className='text-sm font-bold'>+971 502310110</span>
            </div>

        </div>

        <div className='flex items-center gap-2'>
            <div className='h-10 w-10 bg-gray-200 flex justify-center items-center rounded-lg hover:bg-green-500 hover:text-white animations text-green-500 '><FaPhone className='h-6 ' /></div>
            <div className='flex flex-col'>
                <span className='text-sm'>Phone Call</span>
                <span className='text-sm font-bold'>+971 2 563 3881</span>
            </div>

        </div>

        <div className='flex items-center gap-2'>
            <div className='h-10 w-10 bg-gray-200 flex justify-center items-center rounded-lg hover:bg-green-500 hover:text-white animations text-green-500 '><FaEnvelope className='h-6 ' /></div>
            <div className='flex flex-col'>
                <span className='text-sm'>Email Address</span>
                <span className='text-sm font-bold'>info@alafnanhr.com</span>
            </div>

        </div>


        <div className='flex items-center gap-2'>
            <div className='h-10 w-10 bg-gray-200 flex justify-center items-center rounded-lg hover:bg-green-500 hover:text-white animations text-green-500 '><FaMapMarker className='h-6 ' /></div>
            <div className='flex flex-col'>
                <span className='text-sm'>Address</span>
                <span className='text-sm font-bold'>9G45+GC8 - Musaffah - M-33 - Abu Dhabi , UAE</span>
            </div>

        </div>



    </div>
</div>
  )
}

export default MiddleNav