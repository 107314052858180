import React from 'react'
import { FaBell, FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
 

export const TopNav = () => {
  return (
    <div className='w-full h-fit'>
            {/* top nav  */}
            <div className='BG text-white w-full h-20 flex flex-col justify-center items-center'>
                <div className='container bg-red-400x flex justify-between items-center mx-auto'>
                    <div className='font-thin flex gap-2 items-center'>
                        <FaBell />   Welcome to Al Afnan Human Resources Consultancy L.L.C
                    </div>
                    <div className='hidden text-xs md:text-md lg:text-lg md:flex gap-2'>
                        <span className='text-sm'> Mon - Sut: 08 AM to 5 PM - Sun Closed </span>
                        <div className='flex items-center gap-2'>

                            <span href="#">
                                <FaFacebook />
                            </span>
                            <span href="#">
                                <FaTwitter />
                            </span>
                            <span href="#">
                                <FaInstagram />
                            </span>
                            <span href="#">
                                <FaLinkedin />
                            </span>
                            <span href="#">
                                <FaYoutube />
                            </span>

                        </div>

                    </div>
                </div>

            </div>
            </div>
  )
}
