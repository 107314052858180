
import React, { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from '../utils/firebase'; // Assuming you have correctly configured your Firebase setup.
import { useLocation } from 'react-router-dom';
 

const Apply = () => {
  // const searchParams = useSearchParams();
  // const jobId = searchParams.get('j');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Now you can use searchParams.get
  const jobId = searchParams.get('j');
  // const jobId = "123"

  // const searchParams = useSearchParams();
  //   const jobId = searchParams.get('j');

  const [formData, setFormData] = useState({
    phoneCall: '',
    phoneWhatsapp: '',
    fullNames: '',
    resumeFile: null, // Store the file object here
  });

  const [downloadURLL, setDownloadURL] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'file') {
      // Handle file input separately
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const storageRef = ref(storage, `uploads/${formData.resumeFile.name}`);
      await uploadBytes(storageRef, formData.resumeFile);
      const downloadURL = await getDownloadURL(storageRef);
      setDownloadURL(downloadURL); // Store the download URL in the state
      console.log('File uploaded successfully.');
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted

    if (formData.resumeFile) {
      await handleFileUpload(formData.resumeFile); // Upload the file first
    }

    if (!formData.phoneCall || !formData.phoneWhatsapp || !formData.fullNames || !formData.resumeFile) {
      toast.error('All fields are required');
      setLoading(false); // Set loading to false when there's an error
      return;
    }

    try {
      const res = await fetch('https://zia1-git-main-ikerpaster.vercel.app/api/apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobId: jobId,
          phoneCall: formData.phoneCall,
          phoneWhatsapp: formData.phoneWhatsapp,
          fullNames: formData.fullNames,
          resumeFile: downloadURLL, // Use the download URL
        }),
      });

      if (res.status === 201) {
        toast.success('Application has been submitted successfully');
        // You can perform additional actions upon success if needed
      } else if (res.status === 400) {
        const errorMessage = await res.text();
        toast.error(errorMessage);
      } else {
        const errorMessage = await res.text();
        toast.error(errorMessage);
      }
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong during job application.');
    } finally {
      setLoading(false); // Set loading to false when the response is received
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='mb-4'>
        <label className='block text-lg font-semibold mb-1' htmlFor='phoneCall'>
          Phone Call:
        </label>
        <input
          type='text'
          id='phoneCall'
          name='phoneCall'
          value={formData.phoneCall}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-semibold mb-1' htmlFor='phoneWhatsapp'>
          Phone WhatsApp:
        </label>
        <input
          type='text'
          id='phoneWhatsapp'
          name='phoneWhatsapp'
          value={formData.phoneWhatsapp}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-semibold mb-1' htmlFor='fullNames'>
          Full Names:
        </label>
        <input
          type='text'
          id='fullNames'
          name='fullNames'
          value={formData.fullNames}
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <div className='mb-4'>
        <label className='block text-lg font-semibold mb-1' htmlFor='resumeFile'>
          Resume File:
        </label>
        <input
          type='file'
          id='resumeFile'
          name='resumeFile'
          accept='.pdf'
          onChange={handleChange}
          className='w-full p-2 border border-gray-300 rounded'
        />
      </div>

      <button
        type='submit'
        className='bg-blue-500 hover:bg-blue-600 text-white text-lg font-semibold p-2 rounded'
        disabled={loading} // Disable the button when loading
      >
        {loading ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default Apply;
