
import JobCard from '../components/JobCard';
 
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useSWR from 'swr';

const fetcher = async (url) => {
  const res = await fetch(url);
  const data = await res.json();
  if (!res.ok) {
    const error = new Error(data.message);
    throw error;
  }

  return data;
};


 
  export default function JobListing() {
  const [searchParams] = useSearchParams();
  const app = searchParams.get('c');
  




  const { data, error } = useSWR('https://zia1-git-main-ikerpaster.vercel.app/api/Jobs', fetcher);

  const [jobs, setJobs] = useState([]);
  const [searchCategory, setSearchCategory] = useState(app);
  const [searchTitle, setSearchTitle] = useState('');
  const [searchLocation, setSearchLocation] = useState(''); 
  const [sortOrder, setSortOrder] = useState('asc'); // Default to sort by title ascending
  const [sortDate, setSortDate] = useState('newest'); // Default to sort by newest jobs

// start for category 
  const [catData, setCatData] = useState(null);
const [catError, setCatError] = useState(null);

useEffect(() => {
  fetch('https://zia1-git-main-ikerpaster.vercel.app/api/categories')
    .then((response) => response.json())
    .then((data) => {
      setCatData(data);
    })
    .catch((error) => {
      setCatError(error);
    });
}, []);

// End Category  


  useEffect(() => {
    if (data && data.data) {
      setJobs(data.data);
    }
  }, [data]);

  const handleSort = (field) => {
    const sortedJobs = [...jobs];
    sortedJobs.sort((a, b) => {
      if (field === 'title') {
        return sortOrder === 'asc' ? a.title?.localeCompare(b.title) : b.title?.localeCompare(a.title);
      } else if (field === 'date') {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return sortDate === 'newest' ? dateB - dateA : dateA - dateB;
      }
      return 0;
    });

    setJobs(sortedJobs);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSortDate(sortDate === 'newest' ? 'oldest' : 'newest');
  };

  // const filteredJobs = jobs.filter((job) => {
  //   return (
  //     job.category.toLowerCase().includes(searchCategory.toLowerCase()) &&
  //     job.title.toLowerCase().includes(searchTitle.toLowerCase())   
  //   );
  // });

  const filteredJobs = jobs.filter((job) => {
    // Category and Title filtering remains the same
    const matchCategory = job?.category?.includes(searchCategory);
    const matchTitle = job?.title?.toLowerCase().includes(searchTitle.toLowerCase());
  
    // Location filtering for country, state, or city
    const matchLocation = (
      job?.location?.country.includes(searchLocation) ||
      job?.location?.state.includes(searchLocation) ||
      job?.location?.city.includes(searchLocation)
    );
  
    return matchCategory && matchTitle && matchLocation;
  });

  


  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }
console.log("jobs for the job user::: =>",jobs);




console.log("this is the category:: ",catData);


  return (
    <div className="w-full p-4 md:p-6 lg:p-8">
      <div className="font-semibold text-xl md:text-2xl lg:text-3xl">Job List</div>
      <div className="flex flex-wrap justify-center space-y-2 md:space-y-0 md:space-x-2 lg:space-x-2">
        {/* <input
          className="w-full md:w-1/3 lg:w-1/4 p-2 md:p-4 lg:p-4 rounded-lg border border-gray-300 focus:outline-none"
          type="text"
          placeholder="Search by category"
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
        /> */}
  <select
          name="job_category"
          id="job_category"
          className="w-full md:w-1/3 lg:w-1/4 p-2 md:p-4 lg:p-4 rounded-lg border border-gray-300 focus:outline-none"
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
        >
          {!catData ? (
            <option>Job Category is Loading</option>
          ) : (
            

            catData?.data.map((e, i) => (
              <option key={i} value={e.name}>
                {e.name}
              </option>
            ))
          )}
        </select>


        <input
          className="w-full md:w-1/3 lg:w-1/4 p-2 md:p-4 lg:p-4 rounded-lg border border-gray-300 focus:outline-none"
          type="text"
          placeholder="Search by title"
          value={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      
        <div className="space-x-2 md:space-x-4 lg:space-x-6">
          <button
            className="btnSort"
            onClick={() => handleSort('name')}
          >
            Sort by Names {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
          </button>
          <button
            className="btnSort"
            onClick={() => handleSort('date')}
          >
            Sort by Date {sortDate === 'newest' ? 'Newest' : 'Oldest'}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {filteredJobs.length === 0 ? (
          <div>Data Not Found</div>
        ) : (
          filteredJobs.map((job, index) => (
            <JobCard key={index} data={job} />
          ))
        )}
      </div>
    </div>

  );
};

 
