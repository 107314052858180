import React from 'react'

const JobDetailsSkeleton = () => {
  return (
    <div class="w-[75%] flex flex-col h-screen p-5 bg-red-500x">
  <div class="bg-gray-50 my-2 p-10 shadow-lg w-full">
    <div class="flex justify-between">
      <div class="font-bold text-xl animate-pulse bg-gray-200 h-6 w-3/4"></div>
      <div class="text-gray-600 animate-pulse bg-gray-200 h-6 w-1/4"></div>
    </div>
    <div class="text-lg font-semibold animate-pulse bg-gray-200 h-6 w-1/2 mt-4"></div>
    <div class="text-gray-600 animate-pulse bg-gray-200 h-6 w-1/4 mt-2"></div>
  </div>

  <div class="my-10 animate-pulse bg-gray-200 h-48 w-full"></div>

  <div class="font-bold">
    <div class="text-xl animate-pulse bg-gray-200 h-6 w-3/4"></div>
    <div class="text-xl animate-pulse bg-gray-200 h-6 w-3/4 mt-2"></div>
    <div class="text-xl animate-pulse bg-gray-200 h-6 w-1/2 mt-2"></div>
  </div>
</div>

  )
}

export default JobDetailsSkeleton