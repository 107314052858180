import { FaFileDownload, FaBars } from "react-icons/fa";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useState } from "react";

export default function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const menu = [
    {
      name: "About Us",
      page: "https://www.alafnanhr.com/about",
    },
    {
      name: "Our Services",
      page: "#",
      subNav: [
        {
          name: "Executive Talent Quest",
          page: "https://www.alafnanhr.com/executive-talent",
        },
        {
          name: "Global Talent Bridge",
          page: "https://www.alafnanhr.com/global-talent-bridge",
        },
        {
          name: "Strategic HR Advisory",
          page: "https://www.alafnanhr.com/strategic-hr-advisory",
        },
      ],
    },
    {
      name: "Area of Expertise",
      page: "https://www.alafnanhr.com/area-of-expectise",
    },
    {
      name: "Professional Resonance",
      page: "https://www.alafnanhr.com/professional-resonance",
    },
    {
      name: "Jobs",
      page: "https://jobs.alafnanhr.com/",
    },
    {
      name: "Connect With Us",
      page: "https://www.alafnanhr.com/contact",
    },
  ];

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="BG text-white p-4">
      <div className="container mx-auto flex flex-col">
        <div className=" md:hidden w-full flex justify-between items-center">
          <a href="/" className="text-2xl font-semibold hover:text-green-600 mx-6">
            <img src="/images/logo.png" width={100} height={100} alt="Logo" />
          </a>
          <div className="md:hidden">
            <button onClick={toggleMobileMenu} className="text-2xl">
              <FaBars />
            </button>
          </div>
        </div>
        <div className={`mx-6 md:flex justify-between md:space-x-6 ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
          <div className={`flex  gap-4 ${isMobileMenuOpen ? 'flex-col' : ''} `}>

         
          {menu.map((e) => (
            <CustomLink key={e.name} to={e.page} submenu={e.subNav}>
              {e.name}
            </CustomLink>
          ))}
           </div>
          <a href="/doc/Profile-Al Afnan Human Resources Consultancy L.L.C.pdf" type="download" download className="border-2 border-green-600 hover:bg-green-600 animations flex items-center gap-2  text-white p-3 rounded-md">
            Download <FaFileDownload />
          </a>
        </div>
      </div>
    </nav>
  );
}

function CustomLink({ to, children, submenu, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname });

  return (
    <div className="relative group flex flex-col">
      <a
        href={to}
        {...props}
        className={`p-2 text-lg cursor-pointer font-semibold text-gray-200 hover:text-green-600 animations`}
      >
        {children}
      </a>
      {submenu && (
        <div className="absolute flex flex-col bg-white z-20 border border-gray-200 p-2 space-y-1 w-[300px] top-10 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          {submenu.map((item) => (
            <a
              key={item.name}
              href={item.page}
              className="text-gray-600 hover:text-gray-900 py-2 text-lg w-full"
            >
              {item.name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
}
